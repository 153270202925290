<template>
  <section>
    <div class="text-h3 title">
      {{ $t("battery_health") }}
    </div>
    <div class="content-boxes">
      <v-card
        v-for="(row, i) in batteryHistory"
        :key="i"
        :loading="loading"
      >
        <div class="inner-content">
          <span class="icon-circle">
            <icons
              :name="row.icon"
              size="sm"
            />
          </span>
          <div class="">
            <p class="d-flex flex-column align-items-start">
              <span class="label">{{ $t(row.label) }}</span>
              <span class="unit">{{ row.unit }}</span>
            </p>
            <p>
              <span class="value">{{ row.value || 0 }}</span>
            </p>
          </div>
        </div>
      </v-card>
      <!--  -->
    </div>
  </section>
</template>
<script>
export default {
  components: { Icons: () => import("@/components/base/icons.vue") },

  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    batteryHistory() {
      const arr = [
        {
          icon: "heart-pluse",
          label: "state-of-health",
          value: this.item?.state_of_health
            ? `${this.item.state_of_health}%`
            : "N/A",
        },
        {
          icon: "cycle-count",
          label: "cycle-count",
          value: this.item?.cycle_count || "N/A",
        },
        {
          icon: "battery-voltage",
          label: "battery-voltage",
          value: this.item?.Batt_voltage || "N/A",
          unit: "V",
        },
        // {
        //   icon: "battery-current",
        //   label: "batt_current",
        //   value: this.item?.Batt_current || 'N/A',
        //   unit: "mAh",
        // },
        {
          icon: "battery-temperature",
          label: "temperature",
          value: this.item?.Batt_temperature || "N/A",
          unit: "Celsius",
        },
      ];

      return arr;
    },
  },
};
</script>
<style lang="sass" scoped>
.title
  font-weight: 600
.content-boxes
  margin-top: 1rem
  display: flex
  flex: 1
  gap: 1rem
  flex-wrap: wrap
  .v-card
    min-width: 32%
    .inner-content
      padding: 1rem

      .icon-circle
        background: #20a390
        border-radius: 100%
        width: 50px
        height: 50px
        display: flex
        justify-content: center
        align-items: center
      div
        display: flex
        justify-content: space-between
        align-items: center
        .unit
          color: #b1abab
        .value
          font-weight: 600
          font-size: 2.5rem
</style>
